<template>
  <div class="filter-sort-data">
    <div class="sort-data-item" v-for="item in filterData" :key="item.id">
      <img src="/imgs/product/product-c-logo.png" />
      <div class="item-info">
        <div class="item-title">{{item.title}}</div>
        <div class="item-desc">{{item.desc}}</div>
        <div class="item-content">
          <div>操作系统: {{item.system}}</div>
          <div>交付方式: {{item.paymentMethod}}</div>
          <div>发布时间: {{item.releaseTime}}</div>
        </div>
        <div class="item-serviceProvider">服务商: <span class="color-l">{{item.serviceProvider}}</span></div>
        <div class="skill-item" v-for="it in item.skills" :key="it">{{it}}</div>
      </div>
      <div class="color-r item-money">{{"￥"+item.money}}</div>
    </div>
  </div>

</template>

<script>
export default {
  name: "listItem",
  data() {
    return {
      filterData:[
        {id: 1, title: '宏达门诊收费管理系统',
          desc: '协助各医院进行门诊收费管理的好帮手。系统配有详细的开发帮助和操作演示',
          system: 'Windows',paymentMethod:'镜像', releaseTime:'2022-03-10',money: 1200,
          serviceProvider:'聊城市开发区宏达软件科技有限公司',use: 'ECS使用费',skills:['智能制造','移动营销','移动医疗','数字化政府']},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/base.scss";
@import "../../assets/scss/config.scss";
.filter-sort-data {
  .sort-data-item {
    display: flex;
    img {
      width: 100px;
      height: 100%;
      padding: 20px;
    }
    .item-info {
      width: 732px;
      padding: 20px;
      .item-title {
        font-size: 20px;
        color: #101010;
      }
      .item-desc {
        margin: 8px 0;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap
      }
      .item-content {
        display: flex;
        div {
          flex: 1;
        }
      }
      .item-serviceProvider {
        margin: 8px 0;
      }
      .skill-item {
        display: inline-block;
        border: 1px solid #ccc;
        margin: 4px 5px;
        padding: 3px 5px;
        border-radius: 4px;
      }
    }
    .item-money {
      font-size: 16px;
      font-weight: 600;
      line-height: 132px;
    }
  }
  .color-r {
    color: red;
  }
  .color-l {
    color: #2299F5;
  }
}
</style>
