import request from "@/util/request";

// 产品搜索参数
export const searchParameters = data => {
    return request({
        url: "/new_gateway/tntlinking-sso-authcenter/product/searchParameters",
        method: "get",
        params: data
    });
};
