<template>
  <div class="filter-list container">
    <div class="filter-item">
      <div class="filter-item-count">
        <button class="btn">按钮</button>
        <span class="filter-item-count-title">产品</span>
        <span class="filter-item-count-text">共100</span>
      </div>
      <div class="filter-item-box">
        <div class="filter-item-level">
          <div class="filter-item-level-title">产品分类</div>
          <div class="filter-item-level-name" v-for="(item) in levelList" :key="item.id">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="filter-item-level">
          <div class="filter-item-level-title">二级分类</div>
          <div class="filter-item-level-name" v-for="(item) in levelTwoList" :key="item.id">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="filter-item-level">
          <div class="filter-item-level-title">三级分类</div>
          <div class="filter-item-level-name" v-for="(item) in levelThreeList" :key="item.id">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="filter-item-level">
          <div class="filter-item-level-title">交付方式</div>
          <div class="filter-item-level-name" v-for="(item) in DeliveryMethod" :key="item.code">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="filter-item-level">
          <div class="filter-item-level-title">价格选择</div>
          <div class="filter-item-level-name" v-for="(item) in priceList" :key="item.id">
            <span>{{item.name}}</span>
          </div>
          <div class="filter-item-level-price">
            <input
              v-model="price1"
              type="text"
              placeholder="" />
            <span> - </span>
            <input
              v-model="price2"
              type="text"
              placeholder=""/>
            <button class="btn-confirm">确定</button>
            <button class="btn-reset">清空</button>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-list-data">
      <!--  列表    -->
      <div class="filter-list-box">
        <div class="filter-list-sort">
          <div class="sort-comprehensive">综合排序</div>
          <div class="sort-price">价格</div>
          <div class="sort-time">发布时间</div>
        </div>
        <!--    列表项    -->
        <list-item></list-item>
      </div>
      <!--  猜你喜欢    -->
      <div class="filter-list-like">
        <div class="filter-list-like-title">猜你喜欢</div>
        <like-list></like-list>
      </div>
    </div>
    <el-pagination
        v-show="!isShowLoading"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pagesize"
        @current-change="changePagination">
    </el-pagination>
    <no-data v-if="isShowLoading == false && filterData.length == 0"></no-data>
  </div>
</template>

<script>
import ListItem from "../components/filterList/listItem";
import LikeList from "../components/filterList/likeList";
import NoData from "../components/noData";
import {Pagination} from "element-ui";
import {searchParameters} from "../api/filterList"
export default {
  name: "filterList",
  components: {LikeList, ListItem, [Pagination.name]: Pagination, NoData},
  data() {
    return {
      isShowLoading: true,
      total: 1,
      pagesize: 10,
      currentNum: 1,
      price1: '',
      price2: '',
      levelList:[
        {id: 1, name: '全部'},
        {id: 2, name: '医疗软件'},
        {id: 3, name: '开源'},
        {id: 4, name: '网站建设'},
        {id: 5, name: '企业应用'},
        {id: 6, name: '物联网'},
        {id: 7, name: '物联网人工智能'},
      ],
      levelTwoList:[
        {id: 1, name: '全部'},
        {id: 2, name: '医院信息化系统'},
        {id: 3, name: '医院管理与保障信息系统'},
        {id: 4, name: '临床信息系统'},
        {id: 5, name: '就诊管理与服务信息系统'},
      ],
      levelThreeList:[
        {id: 1, name: '全部'},
        {id: 2, name: 'HIS系统'},
        {id: 3, name: 'EMR电子病历'},
        {id: 4, name: '药房管理系统'},
        {id: 5, name: '药库管理系统'},
        {id: 6, name: '挂号系统'},
        {id: 7, name: '大屏叫好系统'},
        {id: 8, name: 'PACS系统'},
        {id: 9, name: 'LIS系统'},
      ],
      DeliveryMethod: [{code:'',name: '全部'}],
      priceList: [
        {id: 1, name: '全部'},
        {id: 2, name: '免费'},
        {id: 3, name: '开源'},
        {id: 4, name: '1-98'},
        {id: 5, name: '99-998'},
        {id: 6, name: '999-9998'},
        {id: 7, name: '9998以上'},
      ],
      filterData: [],
    }
  },
  mounted() {
    this.initData()
  },
  methods:{
    initData() {
      searchParameters().then(res => {
        if(res.code === 200) {
          this.DeliveryMethod.push(...res.data.DeliveryMethod)
          // this.DeliveryMethod = res.data.DeliveryMethod
        }
        console.log(res,'0909')
      })
    },
    changePagination(currentNum) {
      // this.isShowPageLoading = true;
      this.currentNum = currentNum;
      // this.setBasedata().then(() => {
      //   this.isShowPageLoading = false;
      // });
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },

}
</script>

<style scoped lang="scss">
.filter-list {
  font-size: 14px;
  .filter-item {
    .filter-item-count {
      margin-bottom: 16px;
      .filter-item-count-title {
        color: #2299F5;
        font-size: 20px;
        margin-right: 10px;
      }
    }
    .filter-item-box {
      .filter-item-level {
        height: 52px;
        line-height: 52px;
        display: flex;
        border-bottom: 1px solid #cccccc;
        .filter-item-level-title {
          margin-right: 20px;
        }
        .filter-item-level-name {
          margin-right: 10px;
          cursor: pointer;
          span {
            display: inline-block;
            padding: 0px 12px;
            border-radius: 4px;
            height: 30px;
            line-height: 30px;
            &.selected {
              border: 1px solid #2299F5;
            }
          }
        }
        .filter-item-level-price {
          input {
            width: 100px;
            height: 32px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            padding-left: 10px;
            border-radius: 2px;
          }
          button {
            width: 56px;
            height: 30px;
            line-height: 30px;
            cursor: pointer;
            border-radius: 2px;
          }
          .btn-confirm {
            color: #ffffff;
            background-color: #2299F5;
            border: 1px solid #2299F5;
            margin: 0 10px;
          }
          .btn-reset {
            background-color: #ffffff;
            border: 1px solid #cccccc;
          }
        }
      }
    }
  }
  .filter-list-data {
    display: flex;
    .filter-list-box {
      width: 1000px;
      .filter-list-sort {
        height: 50px;
        line-height: 50px;
        display: flex;
        div {
          cursor: pointer;
        }
        .sort-comprehensive {

        }
        .sort-price {
          margin: 0 20px;
        }
        .sort-time {

        }
        .sort-price,
        .sort-time {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 26px;
            right: -10px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid #ADABAB;
          }
          &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 19px;
            right: -10px;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #ADABAB;
          }
        }
      }
    }
    .filter-list-like {
      width: 226px;
      .filter-list-like-title {
        text-align: left;
        padding: 20px;
      }
    }
  }
}
</style>
