<template>
  <div class="like-list">
    <div class="list-item" v-for="item in likeList" :key="item.id">
      <img src="/imgs/product/product-c-logo.png" />
      <div class="item-title">{{item.title}}</div>
      <div class="item-desc">{{item.desc}}</div>
      <div><span class="color-r">{{'￥'+item.cost}}</span>/月</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "likeList",
  data() {
    return {
      likeList: [
        {id: 1, title: '智慧教学云平台', desc: 'U+新工科智慧云通过信息技术与教育学深度融合将场景潘地，贯穿完整的人才培养链接...', cost: 12500}
      ],
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixin.scss";
@import "../../assets/scss/base.scss";
@import "../../assets/scss/config.scss";
.like-list {
  text-align: center;
  .list-item {
    padding: 10px;
    border-bottom: 1px solid #cccccc ;
    img {
      width: 60px;
    }
    .item-title {
      font-size: 16px;
      color: #101010;
      margin: 8px 0;
    }
    .item-desc {
      margin-bottom: 10px;
      text-align: left;
    }
  }
  .color-r {
    color: red;
  }
}
</style>
